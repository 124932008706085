
import './Home.scss'
import Carousell from '../../components/Carousel/Carousel.jsx'
import {useMediaQuery} from 'react-responsive'
import {motion} from 'motion/react'
import { useEffect, useState } from 'react'

const Home = () => {
  const isDesktop = useMediaQuery({ minWidth: 769 });
  const [widthScreen, setWidthScreen] = useState(window.innerWidth);
  const handleResize = () => {
    setWidthScreen(window.innerWidth);
  };
  const svgVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 2, staggerChildren: 0.2 },
    },
  };

  const pathVariants = {
    hidden: { strokeDasharray: "1000", strokeDashoffset: "1000" },
    visible: {
      strokeDashoffset: 0,
      transition: { duration: 2, ease: "easeInOut" },
    },
  };

  const groupVariants = {
    hidden: { scale: 0.8 },
    visible: {
      scale: 1,
      transition: { type: "spring", stiffness: 50 },
    },
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [widthScreen]);
  return (
    <div className="container home">
      <div className="text-zone">
        {isDesktop ? (
          <h1>
            Bienvenue sur mon portfolio.
          </h1>
        ) : (
          <>
          <motion.svg
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            width={widthScreen * 0.8 }
            height="73.601"
            viewBox="0 0 508 73.601"
            xmlns="http://www.w3.org/2000/svg"
            variants={svgVariants}
          >

            <motion.g
              id="svgGroup"
              strokeLinecap="round"
              fontSize="9pt"
              stroke="#00FFFFFF"
              strokeWidth="3"
              fill="none"
              variants={groupVariants}
            >
              <motion.path
                d="M 24.2 72.4 L 0 72.4 L 0 2.4 L 23.5 2.4 Q 30.9 2.4 35.7 4.8 A 19.117 19.117 0 0 1 39.662 7.402 A 15.098 15.098 0 0 1 42.85 11.2 A 17.18 17.18 0 0 1 45.198 19.795 A 20.262 20.262 0 0 1 45.2 20.1 A 20.486 20.486 0 0 1 44.827 24.118 Q 44.325 26.627 43.149 28.619 A 12.598 12.598 0 0 1 42.85 29.1 Q 40.5 32.7 36.75 34.6 Q 33 36.5 28.9 37 L 30.2 36 Q 34.6 36.1 38.4 38.4 Q 42.2 40.7 44.5 44.55 A 16.328 16.328 0 0 1 46.744 51.795 A 19.894 19.894 0 0 1 46.8 53.3 Q 46.8 58.5 44.25 62.85 A 17.499 17.499 0 0 1 38.77 68.579 A 21.949 21.949 0 0 1 36.7 69.8 Q 31.7 72.4 24.2 72.4 Z M 7 39.2 L 7 66.5 L 23.7 66.5 A 25.761 25.761 0 0 0 28.291 66.117 Q 32.654 65.326 35.45 62.9 A 11.799 11.799 0 0 0 39.442 55.358 A 16.953 16.953 0 0 0 39.6 53 A 14.737 14.737 0 0 0 38.909 48.385 A 12.012 12.012 0 0 0 35.25 42.95 A 14.684 14.684 0 0 0 29.407 39.931 Q 27.216 39.337 24.62 39.226 A 28.483 28.483 0 0 0 23.4 39.2 L 7 39.2 Z M 7 8.3 L 7 33.4 L 23.3 33.4 A 23.77 23.77 0 0 0 27.51 33.051 Q 31.617 32.311 34.2 30 A 11.191 11.191 0 0 0 37.866 22.891 A 15.832 15.832 0 0 0 38 20.8 A 13.78 13.78 0 0 0 37.389 16.605 A 11.172 11.172 0 0 0 34.3 11.75 A 11.449 11.449 0 0 0 30.231 9.362 Q 28.488 8.748 26.343 8.489 A 27.94 27.94 0 0 0 23 8.3 L 7 8.3 Z"
                id="0"
                variants = {pathVariants}

                style={{ strokeDasharray: "1000", strokeDashoffset: "500" }}
                initial="hidden"
                animate="visible"
              />
              <motion.path
                d="M 66.9 72.4 L 59.9 72.4 L 59.9 22 L 66.9 22 L 66.9 72.4 Z M 63.4 10.1 Q 61.3 10.1 59.8 8.65 A 4.839 4.839 0 0 1 58.301 5.137 A 6.352 6.352 0 0 1 58.3 5 A 5.892 5.892 0 0 1 58.504 3.409 A 4.363 4.363 0 0 1 59.75 1.35 A 4.992 4.992 0 0 1 62.763 0.03 A 6.664 6.664 0 0 1 63.4 0 Q 65.4 0 66.9 1.35 A 4.45 4.45 0 0 1 68.347 4.171 A 6.308 6.308 0 0 1 68.4 5 A 5.474 5.474 0 0 1 68.092 6.869 A 4.797 4.797 0 0 1 66.95 8.65 A 4.819 4.819 0 0 1 63.465 10.1 A 6.039 6.039 0 0 1 63.4 10.1 Z"
                id="1"
                variants = {pathVariants}
              />
              <motion.path
                d="M 126.5 48.8 L 85.1 48.8 L 85.1 42.9 L 119.8 42.9 A 21.798 21.798 0 0 0 119.064 37.919 A 14.437 14.437 0 0 0 114.95 31 A 15.705 15.705 0 0 0 105.599 26.903 A 20.994 20.994 0 0 0 103.5 26.8 Q 99.1 26.8 95.2 28.85 Q 91.3 30.9 88.95 34.85 Q 86.839 38.399 86.624 43.603 A 29.09 29.09 0 0 0 86.6 44.8 L 86.6 47.4 Q 86.6 54.2 89.05 58.7 A 18.671 18.671 0 0 0 91.764 62.52 A 15.059 15.059 0 0 0 95.4 65.4 Q 99.3 67.6 103.6 67.6 Q 109.3 67.6 113 65.05 A 13.908 13.908 0 0 0 118.145 58.418 A 17.051 17.051 0 0 0 118.3 58 L 125.2 58 Q 124 62.4 121.1 65.95 A 20.313 20.313 0 0 1 114.074 71.421 A 23.48 23.48 0 0 1 113.8 71.55 A 21.459 21.459 0 0 1 108.081 73.262 A 28.496 28.496 0 0 1 103.6 73.6 A 25.227 25.227 0 0 1 96.628 72.669 A 21.426 21.426 0 0 1 91.25 70.3 A 22.697 22.697 0 0 1 83.141 61.602 A 27.094 27.094 0 0 1 82.85 61.05 A 27.173 27.173 0 0 1 80.265 53.038 A 36.35 36.35 0 0 1 79.8 47.1 A 35.988 35.988 0 0 1 80.416 40.281 A 26.686 26.686 0 0 1 82.8 33.2 Q 85.8 27.3 91.25 24.05 A 23.077 23.077 0 0 1 100.773 20.956 A 28.845 28.845 0 0 1 103.8 20.8 A 26.867 26.867 0 0 1 109.717 21.421 A 19.866 19.866 0 0 1 116.2 24.1 Q 121.3 27.4 123.95 32.7 A 25.387 25.387 0 0 1 126.59 43.517 A 29.52 29.52 0 0 1 126.6 44.3 L 126.6 46.35 A 26.438 26.438 0 0 1 126.586 47.187 Q 126.571 47.648 126.541 48.163 A 48.098 48.098 0 0 1 126.5 48.8 Z"
                id="2"
                variants = {pathVariants}
              />
              <motion.path
                d="M 145 72.4 L 138 72.4 L 138 22 L 144.4 22 L 144.8 31 Q 147.2 26.2 151.75 23.5 A 19.439 19.439 0 0 1 161.551 20.803 A 23.028 23.028 0 0 1 161.9 20.8 A 24.655 24.655 0 0 1 167.008 21.306 A 18.749 18.749 0 0 1 172 23.1 A 15.728 15.728 0 0 1 178.313 29.338 A 19.278 19.278 0 0 1 178.7 30.05 Q 180.675 33.878 181.025 39.433 A 39.321 39.321 0 0 1 181.1 41.9 L 181.1 72.4 L 174.1 72.4 L 174.1 42.7 Q 174.1 34.7 170.6 30.8 A 11.381 11.381 0 0 0 164.417 27.281 A 17.151 17.151 0 0 0 160.7 26.9 A 16.099 16.099 0 0 0 155.584 27.694 A 14.467 14.467 0 0 0 152.65 29.05 Q 149.1 31.2 147.05 35.3 A 18.292 18.292 0 0 0 145.529 39.797 Q 145.103 41.854 145.02 44.229 A 33.509 33.509 0 0 0 145 45.4 L 145 72.4 Z"
                id="3"
                variants = {pathVariants}
              />
              <motion.path
                d="M 217.1 72.4 L 209.1 72.4 L 189.9 22 L 197.2 22 L 213.2 65.6 L 229.2 22 L 236.4 22 L 217.1 72.4 Z"
                id="4"
                variants = {pathVariants}
              />
              <motion.path
                d="M 290.6 48.8 L 249.2 48.8 L 249.2 42.9 L 283.9 42.9 A 21.798 21.798 0 0 0 283.164 37.919 A 14.437 14.437 0 0 0 279.05 31 A 15.705 15.705 0 0 0 269.699 26.903 A 20.994 20.994 0 0 0 267.6 26.8 Q 263.2 26.8 259.3 28.85 Q 255.4 30.9 253.05 34.85 Q 250.939 38.399 250.724 43.603 A 29.09 29.09 0 0 0 250.7 44.8 L 250.7 47.4 Q 250.7 54.2 253.15 58.7 A 18.671 18.671 0 0 0 255.864 62.52 A 15.059 15.059 0 0 0 259.5 65.4 Q 263.4 67.6 267.7 67.6 Q 273.4 67.6 277.1 65.05 A 13.908 13.908 0 0 0 282.245 58.418 A 17.051 17.051 0 0 0 282.4 58 L 289.3 58 Q 288.1 62.4 285.2 65.95 A 20.313 20.313 0 0 1 278.174 71.421 A 23.48 23.48 0 0 1 277.9 71.55 A 21.459 21.459 0 0 1 272.181 73.262 A 28.496 28.496 0 0 1 267.7 73.6 A 25.227 25.227 0 0 1 260.728 72.669 A 21.426 21.426 0 0 1 255.35 70.3 A 22.697 22.697 0 0 1 247.241 61.602 A 27.094 27.094 0 0 1 246.95 61.05 A 27.173 27.173 0 0 1 244.365 53.038 A 36.35 36.35 0 0 1 243.9 47.1 A 35.988 35.988 0 0 1 244.516 40.281 A 26.686 26.686 0 0 1 246.9 33.2 Q 249.9 27.3 255.35 24.05 A 23.077 23.077 0 0 1 264.873 20.956 A 28.845 28.845 0 0 1 267.9 20.8 A 26.867 26.867 0 0 1 273.817 21.421 A 19.866 19.866 0 0 1 280.3 24.1 Q 285.4 27.4 288.05 32.7 A 25.387 25.387 0 0 1 290.69 43.517 A 29.52 29.52 0 0 1 290.7 44.3 L 290.7 46.35 A 26.438 26.438 0 0 1 290.686 47.187 Q 290.671 47.648 290.641 48.163 A 48.098 48.098 0 0 1 290.6 48.8 Z"
                id="5"
                variants = {pathVariants}
              />
              <motion.path
                d="M 309.1 72.4 L 302.1 72.4 L 302.1 22 L 308.5 22 L 308.9 31 Q 311.3 26.2 315.85 23.5 A 19.439 19.439 0 0 1 325.651 20.803 A 23.028 23.028 0 0 1 326 20.8 A 24.655 24.655 0 0 1 331.108 21.306 A 18.749 18.749 0 0 1 336.1 23.1 A 15.728 15.728 0 0 1 342.413 29.338 A 19.278 19.278 0 0 1 342.8 30.05 Q 344.775 33.878 345.125 39.433 A 39.321 39.321 0 0 1 345.2 41.9 L 345.2 72.4 L 338.2 72.4 L 338.2 42.7 Q 338.2 34.7 334.7 30.8 A 11.381 11.381 0 0 0 328.517 27.281 A 17.151 17.151 0 0 0 324.8 26.9 A 16.099 16.099 0 0 0 319.684 27.694 A 14.467 14.467 0 0 0 316.75 29.05 Q 313.2 31.2 311.15 35.3 A 18.292 18.292 0 0 0 309.629 39.797 Q 309.203 41.854 309.12 44.229 A 33.509 33.509 0 0 0 309.1 45.4 L 309.1 72.4 Z"
                id="6"
                variants = {pathVariants}
              />
              <motion.path
                d="M 357.4 52.5 L 357.4 22 L 364.4 22 L 364.4 51.7 Q 364.4 56.712 365.793 60.115 A 11.713 11.713 0 0 0 367.95 63.6 A 11.64 11.64 0 0 0 374.56 67.209 A 17.155 17.155 0 0 0 377.8 67.5 A 16.099 16.099 0 0 0 382.916 66.707 A 14.467 14.467 0 0 0 385.85 65.35 Q 389.4 63.2 391.45 59.1 A 18.292 18.292 0 0 0 392.971 54.604 Q 393.397 52.547 393.48 50.172 A 33.509 33.509 0 0 0 393.5 49 L 393.5 22 L 400.5 22 L 400.5 72.4 L 394.1 72.4 L 393.7 63.4 Q 391.3 68.2 386.75 70.9 A 19.439 19.439 0 0 1 376.949 73.598 A 23.028 23.028 0 0 1 376.6 73.6 Q 370.9 73.6 366.55 71.35 A 15.291 15.291 0 0 1 360.227 65.19 A 18.944 18.944 0 0 1 359.8 64.4 Q 357.4 59.7 357.4 52.5 Z"
                id="7"
                variants = {pathVariants}
              />
              <motion.path
                d="M 459.2 48.8 L 417.8 48.8 L 417.8 42.9 L 452.5 42.9 A 21.798 21.798 0 0 0 451.764 37.919 A 14.437 14.437 0 0 0 447.65 31 A 15.705 15.705 0 0 0 438.299 26.903 A 20.994 20.994 0 0 0 436.2 26.8 Q 431.8 26.8 427.9 28.85 Q 424 30.9 421.65 34.85 Q 419.539 38.399 419.324 43.603 A 29.09 29.09 0 0 0 419.3 44.8 L 419.3 47.4 Q 419.3 54.2 421.75 58.7 A 18.671 18.671 0 0 0 424.464 62.52 A 15.059 15.059 0 0 0 428.1 65.4 Q 432 67.6 436.3 67.6 Q 442 67.6 445.7 65.05 A 13.908 13.908 0 0 0 450.845 58.418 A 17.051 17.051 0 0 0 451 58 L 457.9 58 Q 456.7 62.4 453.8 65.95 A 20.313 20.313 0 0 1 446.774 71.421 A 23.48 23.48 0 0 1 446.5 71.55 A 21.459 21.459 0 0 1 440.781 73.262 A 28.496 28.496 0 0 1 436.3 73.6 A 25.227 25.227 0 0 1 429.328 72.669 A 21.426 21.426 0 0 1 423.95 70.3 A 22.697 22.697 0 0 1 415.841 61.602 A 27.094 27.094 0 0 1 415.55 61.05 A 27.173 27.173 0 0 1 412.965 53.038 A 36.35 36.35 0 0 1 412.5 47.1 A 35.988 35.988 0 0 1 413.116 40.281 A 26.686 26.686 0 0 1 415.5 33.2 Q 418.5 27.3 423.95 24.05 A 23.077 23.077 0 0 1 433.473 20.956 A 28.845 28.845 0 0 1 436.5 20.8 A 26.867 26.867 0 0 1 442.417 21.421 A 19.866 19.866 0 0 1 448.9 24.1 Q 454 27.4 456.65 32.7 A 25.387 25.387 0 0 1 459.29 43.517 A 29.52 29.52 0 0 1 459.3 44.3 L 459.3 46.35 A 26.438 26.438 0 0 1 459.286 47.187 Q 459.271 47.648 459.241 48.163 A 48.098 48.098 0 0 1 459.2 48.8 Z"
                id="8"
                variants = {pathVariants}
              />
              <motion.path
                d="M 505.9 53.4 L 500 53.4 L 499.2 2.4 L 506.8 2.4 L 505.9 53.4 Z M 502.9 72.9 Q 500.7 72.9 499.3 71.4 Q 497.9 69.9 497.9 67.7 A 5.675 5.675 0 0 1 498.183 65.875 A 4.819 4.819 0 0 1 499.3 64.05 A 4.688 4.688 0 0 1 502.461 62.615 A 6.325 6.325 0 0 1 502.9 62.6 A 5.474 5.474 0 0 1 504.769 62.908 A 4.797 4.797 0 0 1 506.55 64.05 A 4.894 4.894 0 0 1 507.994 67.428 A 6.47 6.47 0 0 1 508 67.7 Q 508 69.9 506.55 71.4 A 4.839 4.839 0 0 1 503.037 72.899 A 6.352 6.352 0 0 1 502.9 72.9 Z"
                id="10"
                variants = {pathVariants}
                />
            </motion.g>
          </motion.svg>

          </>
        )}

        {isDesktop && <h2>Voici mes réalisations : </h2>}
      </div>
      <Carousell />
    </div>
  );
};

export default Home;
